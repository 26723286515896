import { Person } from "../pages/wieIsWie";

export default function PersonButton(props: {
  member: Person;
  currentPerson: number;
  index: number;
  setPerson: any;
}) {
  return (
    <article
      onClick={() => props.setPerson(props.index)}
      className={`${props.currentPerson === props.index ? "bg-green text-white" : "bg-yellow"} transition-colorsd flex h-20 shrink-0 cursor-pointer select-none gap-2 overflow-hidden rounded-full p-2 duration-300`}
    >
      <img
        src={"/assets/kandidaten/" + props.member.foto}
        alt=""
        className="aspect-square rounded-full object-cover"
      />
      <div className=" flex h-full flex-col justify-center">
        <h2 className="line-clamp-1 font-koester font-semibold">
          {props.member.naam}
        </h2>
        <h3 className="line-clamp-2 pr-2 text-xs">{props.member.functie}</h3>
      </div>
    </article>
  );
}
