import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, NavLink } from "react-router-dom";
import {
  faPhone,
  faHome,
  faUsers,
  faCalendarDays,
  faNewspaper,
} from "@fortawesome/free-solid-svg-icons";

export default function Navigation({ isOnPostsPage = false }) {
  return (
    <nav className="w-full  md:min-h-24 ">
      <div className="container mx-auto flex items-center justify-between px-4 md:min-h-24 ">
        <Link
          to="/"
          draggable="false"
          className=" hidden h-full flex-col items-center justify-center lg:flex"
        >
          <img
            draggable="false"
            src="/assets/koester2290Logo.png"
            alt="Koester 2290 logo"
            className="h-28 py-2"
          />
        </Link>

        <ul className="my-4 flex h-full w-full flex-row items-center justify-evenly gap-2 md:gap-6 lg:justify-end lg:text-lg">
          <li>
            <NavLink
              draggable="false"
              to="/"
              className={({ isActive }) =>
                `${isActive ? " bg-orangedark" : "bg-yellow hover:opacity-80"} flex select-none items-center gap-2 rounded-lg px-4 py-2 font-bold  text-white md:px-6 md:py-1 lg:px-8`
              }
            >
              <FontAwesomeIcon icon={faHome} className="md:hidden" />
              <span className="hidden md:block">Home</span>
              <span className="sr-only">Home</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/wie-is-wie"
              draggable="false"
              className={({ isActive }) =>
                `${isActive ? " bg-orangedark" : "bg-yellow hover:opacity-80"} flex select-none items-center gap-2 rounded-lg px-4 py-2 font-bold text-white md:px-6 md:py-1 lg:px-8`
              }
            >
              <FontAwesomeIcon icon={faUsers} className="md:hidden" />
              <span className="hidden md:block">Wie is wie?</span>
              <span className="sr-only">Wie is wie?</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/programma"
              draggable="false"
              className={({ isActive }) =>
                `${isActive ? " bg-orangedark" : "bg-yellow hover:opacity-80"} flex select-none items-center gap-2 rounded-lg px-4 py-2 font-bold text-white md:px-6 md:py-1 lg:px-8`
              }
            >
              <FontAwesomeIcon icon={faCalendarDays} className="md:hidden" />
              <span className="hidden md:block">Programma</span>
              <span className="sr-only">Programma</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/publicaties"
              draggable="false"
              className={({ isActive }) =>
                `${isActive ? " bg-orangedark" : "bg-yellow hover:opacity-80"} flex select-none items-center gap-2 rounded-lg px-4 py-2 font-bold text-white md:px-6 md:py-1 lg:px-6`
              }
            >
              <FontAwesomeIcon icon={faNewspaper} className="md:hidden" />
              <span className="hidden md:block">Publicaties</span>
              <span className="sr-only">Publicaties</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/contact"
              draggable="false"
              className={({ isActive }) =>
                `${isActive ? " bg-orangedark" : "bg-yellow hover:opacity-80"} flex select-none items-center gap-2 rounded-lg px-3 py-2 font-bold text-white md:px-6 md:py-1 lg:px-6`
              }
            >
              <FontAwesomeIcon icon={faPhone} className="md:hidden" />
              <span className="hidden md:block">Contact</span>
              <span className="sr-only">Contact</span>
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
}
