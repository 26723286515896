import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPersonDigging, faWarning } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function UnderConstruction({ construction = true }) {
  return (
    <main className="flex w-full flex-col items-center justify-center gap-4 px-4 text-center">
      <Helmet>
        <title>Onder ontwikkeling | Koester 2290</title>
      </Helmet>
      <FontAwesomeIcon
        className="text-8xl text-yellow"
        icon={construction ? faPersonDigging : faWarning}
      />
      <h3 className="text-4xl font-bold">
        {construction ? "Onder ontwikkeling" : "Oops! Pagina Niet Gevonden"}
      </h3>
      <p>
        {construction ? (
          <p>
            We werken momenteel hard aan het verbeteren van onze website. Kom
            snel nog eens terug!
          </p>
        ) : (
          <>
            <p>De pagina die je zoekt, lijkt niet te bestaan.</p>
            <p>
              Misschien is er een typefout gemaakt in de URL of is de pagina
              verplaatst.
            </p>
          </>
        )}
      </p>
      <Link
        to={"/"}
        className="rounded-lg bg-green px-4 py-2 font-semibold text-white"
      >
        Naar startpagina
      </Link>
    </main>
  );
}
