import { Helmet } from "react-helmet";
import PublicatieCard from "../components/publicatieCard";

const publicaties: Publicatie[] = [
  {
    image: "/assets/publicaties/Campagne, brief september.png",
    title: "Campagne, brief september",
    fileName: "Campagne, brief september.pdf",
  },
  {
    image: "/assets/publicaties/Campagne, brief augustus.png",
    title: "Campagne, brief augustus",
    fileName: "Campagne, brief augustus.pdf",
  },
  {
    image: "/assets/publicaties/Folder juni 2024.png",
    title: "Juni 2024",
    fileName: "Folder juni 2024.pdf",
  },
  {
    image: "/assets/publicaties/Folder juni 2023.png",
    title: "Juni 2023",
    fileName: "Folder juni 2023.pdf",
  },
  {
    image: "/assets/publicaties/Folder december 2023.png",
    title: "December 2023",
    fileName: "Folder december 2023.pdf",
  },
];

export type Publicatie = {
  image: string;
  title: string;
  fileName: string;
};

export default function Publicaties() {
  return (
    <article className="background-figures flex w-full items-center bg-yellow">
      <Helmet>
        <title>Publicaties | Koester 2290</title>
      </Helmet>
      <section className="container mx-auto flex flex-col justify-evenly gap-6 px-4 py-8 sm:flex-row">
        <div
          id="publicatie-container"
          className="px- flex w-full flex-wrap justify-evenly gap-8 "
        >
          {publicaties.map((publicatie: Publicatie) => (
            <PublicatieCard publicatie={publicatie} />
          ))}
        </div>
      </section>
    </article>
  );
}
