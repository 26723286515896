import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

import { Helmet } from "react-helmet";

// Updated contactMethods array
const contactMethods: ContactMethod[] = [
  {
    content: "info@koester2290.be",
    link: "mailto:info@koester2290.be",
    icon: faEnvelope,
    bgColor: "rgb(255,129,43)", // koester color
  },
  /* {
    content: "Koester2290",
    link: "#",

    icon: faFacebook,
    bgColor: "#3b5998", // Facebook's color
  },
  {
    content: "@Koester2290",
    link: "#",
    icon: faTwitter,
    bgColor: "#1DA1F2", // Twitter's color
  },
  {
    content: "@koester2290",
    link: "#",
    icon: faInstagram,
    bgColor: "#E4405F", // Instagram's color
  }, */
];
export interface ContactMethod {
  content: string;
  link: string;
  icon: IconProp;
  bgColor: string;
}

export default function Contact() {
  return (
    <article className="background-figures flex w-full border-t-8 border-orangelight">
      <Helmet>
        <title>Contact | Koester 2290</title>
      </Helmet>
      <section className="container mx-auto flex items-center justify-between gap-10 px-4 py-8">
        <div className=" flex  w-full flex-col gap-6 ">
          <header className="flex flex-col">
            <h2 className="font-koester text-4xl font-bold text-green">
              <span className="hyphens-auto break-words">Contacteer ons </span>
            </h2>
            <h3 className="-mt-2 text-xl text-green">
              We horen graag van je! Aarzel niet om contact met ons op te nemen
              als je vragen of opmerkingen hebt.
            </h3>
          </header>

          <ul className="mb-4 p-2 ">
            {contactMethods.map((method: ContactMethod, index: number) => (
              <ContactButton ContactMethod={method} key={index} />
            ))}
          </ul>
        </div>
      </section>
    </article>
  );
}

export function ContactButton(props: { ContactMethod: ContactMethod }) {
  return (
    <li className="group flex max-w-60 grow basis-0 justify-center">
      <a
        href={`${props.ContactMethod.link}`}
        className={` flex grow flex-col gap-2 rounded-2xl  p-4 text-center shadow-lg transition-all duration-300 group-hover:scale-105 `}
        style={{
          backgroundColor: props.ContactMethod.bgColor,
          color: "white",
        }}
      >
        <FontAwesomeIcon
          icon={props.ContactMethod.icon}
          className={`text-4xl transition-all group-hover:scale-110 md:text-5xl`}
        />
        <span className="sr-only">{props.ContactMethod.content}</span>
        <span className="break-all md:text-lg">
          {props.ContactMethod.content}
        </span>
      </a>
    </li>
  );
}
