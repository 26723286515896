export default function Footer() {
  return (
    <footer className="flex h-16 items-center bg-green">
      <div className="container mx-auto flex h-full items-center gap-8 px-4">
        <img
          draggable="false"
          src="/assets/heartLogo.svg"
          alt="Koester logo"
          className="h-full py-3"
        />
        <p className="flex flex-col justify-center text-center font-koester text-xl font-bold leading-5">
          <span className="text-orangedark">Koester</span>
          <span className="text-orangelight">2290</span>
        </p>
      </div>
    </footer>
  );
}
