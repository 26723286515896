import { useEffect, useState } from "react";

import PersonButton from "../components/personButton";
import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../components/ui/carousel";
import { Helmet } from "react-helmet";

export default function WieIsWie() {
  const members: Person[] = [
    /*     {
      naam: "Sarah Johnson",
      functie: "Partijleider",
      context:
        "Toegewijde leider met een visie voor een rechtvaardige en inclusieve samenleving. Sarah heeft een bewezen staat van dienst in het pleiten voor sociale hervormingen en gemeenschapsversterking. Toegewijd aan het bevorderen van transparantie en verantwoordelijkheid in de overheid.",
      foto: "https://api.dicebear.com/7.x/thumbs/svg?seed=Charlie",
    },
    {
      naam: "Michael Rodriguez",
      functie: "Beleidsanalist",
      context:
        "Ervaren beleidsanalist met een diepgaand begrip van economische en sociale kwesties. Michael is gepassioneerd over het ontwikkelen van beleid dat voldoet aan de behoeften van diverse gemeenschappen en duurzame ontwikkeling bevordert.",
      foto: "https://api.dicebear.com/7.x/thumbs/svg?seed=Sammy",
    },
    {
      naam: "Rebecca Carter",
      functie: "Communicatiedirecteur",
      context:
        "Vaardige communicator met een achtergrond in journalistiek en public relations. Rebecca is verantwoordelijk voor het verwoorden van de berichten en waarden van de partij naar het publiek. Toegewijd aan het bevorderen van open dialogen en betrokkenheid bij de kiezers.",
      foto: "https://api.dicebear.com/7.x/thumbs/svg?seed=Simba",
    },
    {
      naam: "Alexandre Dubois",
      functie: "Gemeenschapsorganisator",
      context:
        "Gemeenschapsgerichte organisator toegewijd aan het opbouwen van sterke verbindingen tussen de partij en lokale gemeenschappen. Alexandre werkt aan het aanpakken van de zorgen van burgers en ervoor zorgen dat hun stemmen worden gehoord in het politieke proces.",
      foto: "https://api.dicebear.com/7.x/thumbs/svg?seed=Jasper",
    },
    {
      naam: "Mia Patel",
      functie: "Milieuactivist",
      context:
        "Gepassioneerde voorvechter voor milieukwesties, Mia zet zich in voor het bevorderen van duurzame praktijken en het aanpakken van klimaatverandering. Met een achtergrond in milieukunde brengt ze wetenschappelijke expertise in bij de initiatieven van de partij.",
      foto: "https://api.dicebear.com/7.x/thumbs/svg?seed=Rocky",
    }, */

    {
      naam: "Simon Dens (47)",
      functie:
        "Gewezen schepen, leerkracht in Windekind, geëngageerd in verschillende verenigingen",
      context:
        "Vanuit een groot engagement voor ons dorp, stel ik me kandidaat. Ik hou van Vorselaar en wil mee zorg dragen voor de vele troeven van ons dorp. Met een ambitieus en verbindend verhaal wil ik werk maken van een mooie toekomst voor ons dorp, samen met een sterke ploeg. ",
      foto: "Simon Dens.JPG",
    },
    {
      naam: "Sofie Theunissen (39)",
      functie:
        "Uittredend gemeenteraadslid, juriste, met een hart voor de Vorselaarse zelfstandigen",
      context:
        "Ik koester Vorselaar als een bruisende en warme gemeente! Graag zet ik me in voor de vele jonge gezinnen in onze gemeente en onze lokale ondernemers! ",
      foto: "Sofie Theunissen.JPG",
    },
    {
      naam: "Tjén Mariën (67)",
      functie: "Gewezen schepen, radicaal positief!",
      context:
        "	Ik zie mijn dorp te graag om zomaar langs te kant te blijven staan, daarom engageer ik me 	opnieuw voor mijn dorp. Ik hou van het leven, familie, mensen en Vorselaar.",
      foto: "Tjén Mariën.JPG",
    },
    {
      naam: "Luc Lauwereys (55)",
      functie:
        "Gewezen schepen, met een hart voor het landelijke karakter van ons dorp",
      context:
        "Als gewezen schepen van ruimtelijke ordening vind ik dat we in Vorselaar verstandig moeten omspringen met de open ruimte. Het landelijk karakter van ons dorp moet behouden blijven. Ik ga voor een evenwichtig beleid met aandacht voor ecologie én economie: landbouw verdient meer ondersteuning. Een goede coördinatie en communicatie bij openbare werken is ook belangrijk. ",
      foto: "Luc Lauwereys.JPG",
    },
    {
      naam: "Daphne Hofmans (35)",
      functie:
        "Zelfstandig jurist, mama van Julia, Jane en Jos, echtgenote van Jan Bakelants",
      context:
        "	Onze gemeente telt veel jonge gezinnen. Ik wil meewerken aan een beleid dat aandacht 	heeft voor hen: betaalbare en kwaliteitsvolle kinderopvang, veilige fietsinfrastructuur, een 	breed vrijetijdsaanbod voor kinderen en sterk onderwijs. Daar wil ik mee voor gaan!",
      foto: "Daphne Hofmans.JPG",
    },
    {
      naam: "Annick Van Leemput (53)",
      functie: "Gewezen schepen, voormalig gemeentesecretaris, jurist",
      context:
        "Een gemeente besturen vraagt vandaag heel wat: rekening houden met de verwachtingen van de burger, van het personeel, van hogere overheden en wetten en regels en dat alles binnen de financiële mogelijkheden. De kleinschaligheid van onze gemeente maakt de uitdaging des te groter maar is ook een troef die we moeten koesteren. Voor zo’n doordacht beleid wil ik me graag inzetten.",
      foto: "Annick Van Leemput.JPG",
    },
    {
      naam: "Roel Jacobs (38)",
      functie:
        "Zelfstandige bij Feestverhuur Jacobs, ex-chiroleider en man van Kaatje Van Echelpoel en trotse papa van Otis",
      context:
        "Verkeersveiligheid vind ik een belangrijk thema. Mijn hart ligt bij het rijke Vorselaarse verenigingsleven. Laten we dit koesteren want het is een grote troef voor ons dorp. Daar wil ik me volop voor inzetten. ",
      foto: "Roel Jacobs.JPG",
    },
    {
      naam: "Mathijs Van der Eecken (19)",
      functie:
        "Student politieke en sociale wetenschappen, chiroleider en voetballer bij OG Vorselaar",
      context:
        "Vorselaar is een bruisende gemeente met heel wat activiteiten. Ik vind het belangrijk dat onze gemeente ook bruist voor kinderen en jongeren. Daar zet ik me graag voor in!",
      foto: "Mathijs Van der Eecken.JPG",
    },
    {
      naam: "Fee Vanheukelen (19)",
      functie: "Student communicatiewetenschappen",
      context:
        "De Vorselaarse kleinschaligheid vind ik heel gezellig, het is onze grootste troef. Ik vind het belangrijk dat de gemeente een beleid voert dichtbij de mensen. Ik wil dat ook jongeren meer betrokken worden bij het beleid. ",
      foto: "Fee Vanheukelen.JPG",
    },
    {
      naam: "Joren De Wachter (40)",
      functie: "Consultant, papa van vier sportieve kinderen",
      context:
        "Laat ons verder bouwen op wat goed is in Vorselaar, de lokale ondernemers en het uitgebreide verenigingsleven breder ondersteunen en de focus leggen op een fietsveilige en sociale gemeente.",
      foto: "Joren De Wachter.JPG",
    },
    {
      naam: "Leen Op de Beeck (64)",
      functie:
        "Uittredend raadslid, zorgkundige met een hart voor welzijn en ons landelijk Vorselaar",
      context:
        "Ik wil mij inzetten voor een toegankelijk dorp. Ik ga voor een beleid dat rekening houdt met mensen met een beperking. Daarnaast vind ik dat wegen veilig moeten worden opengesteld en toegankelijk zijn voor iedereen. Tenslotte moeten ondernemers in ons dorp ondersteund worden, zeker ook onze landbouwers. ",
      foto: "Leen Op de Beeck.JPG",
    },
    {
      naam: "Diane Van Hoof (60)",
      functie:
        "Beleidsmedewerker bij OZCS Koepel (de scholen van de Zusters van Vorselaar), met een hart voor Vorselaar als onderwijsdorp",
      context:
        "Ik wil mij inzetten voor een goede samenwerking tussen onderwijspartners, kinderopvang en sportverenigingen. Ook veilig verkeer met voldoende fietspaden en een vlotte doorstroming vind ik belangrijk. Ik wil gaan voor een toegankelijke dorpskern met voldoende parkeergelegenheid, ook voor wie minder mobiel is. ",
      foto: "Diane Van Hoof.JPG",
    },
    {
      naam: "Staf Mertens (67)",
      functie: "Gepensioneerd, lid van badminton",
      context:
        "Ik hou van mijn dorp voor zijn eigenheid en de samenwerking tussen alle verschillende verenigingen. Ik hoop mee te kunnen werken aan de verkeersveiligheid in Vorselaar. ",
      foto: "Staf Mertens.JPG",
    },
    {
      naam: "Frank Hillen (51)",
      functie: "Gekend van ‘Den Tip’, ex-cafébaas",
      context:
        "We moeten onze dorpskern koesteren en zorgen voor een veiliger Vorselaar voor onze zwakke weggebruikers en meer activiteiten <u><b>in</b></u> onze dorpskern organiseren ter ondersteuning van onze kleine zelfstandigen en onze verenigingen. Vorselaar moet een levendig, onafhankelijk dorp blijven. ",
      foto: "Frank Hillen.JPG",
    },
    {
      naam: "Chantal Nelis (51)",
      functie:
        "Zelfstandige massagetherapie, met een hart voor de mensen van ons dorp",
      context:
        "Zorg dragen voor ons dorp, dat is waar mijn hart ligt. Niet alleen zorg voor het dorp, maar ook voor de mensen. Luisteren naar hun klachten en deze samen proberen op te lossen. Als dat lukt, ben ik de gelukkigste mens van het dorp. ",
      foto: "Chantal Nelis.JPG",
    },
    {
      naam: "Thomas Soetemans (28)",
      functie: "Administratief commercieel medewerker, inwoner van het Heiken",
      context:
        "Vorselaar is een karaktervol, landelijk dorp. Dat wil ik mee koesteren en bewaren. Een levendige dorpskern waar het leuk vertoeven is, vind ik belangrijk. En als inwoner van het Heiken wil ik ook aandacht voor de inwoners van de vele buitengebieden in Vorselaar. ",
      foto: "Thomas Soetemans.JPG",
    },
    {
      naam: "Bart Geukens (49)",
      functie:
        "Teamleider bij Mondelez, met een hart voor verenigingen en vrijwilligers",
      context:
        "	Vorselaar is een bruisende gemeente waar veel te beleven valt. Onze vele verenigingen zijn 	de motor voor dit levendige vrijetijdsaanbod. Deze motor draait op vele vrijwilligers, ook steek ik graag een handje toe. Verenigingen verdienen vanuit de gemeente alle ondersteuning	en inspraak, daar ga ik mee voor.",
      foto: "Bart Geukens.JPG",
    },
    {
      naam: "Cecile Goethals (71)",
      functie: "Leerkracht op pensioen, geëngageerd in het verenigingsleven",
      context:
        "Mijn droom? Vorselaar kampioen vrijwilligerswerk. Om kinderen, bejaarden, zieken, ...de warmte en aandacht te geven waar professionelen vaak geen tijd voor krijgen. En wie vrijwilligt, voelt zich nuttig en gewaardeerd.",
      foto: "Cecile Goethals.JPG",
    },
    {
      naam: "Bea Landuyt (69)",
      functie:
        "Uittredend raadslid, gepensioneerd leerkracht KVRI, geëngageerd in de parochie en het verenigingsleven",
      context:
        "In ons dorp heb je nog een sterke persoonlijke betrokkenheid tussen de mensen. Die gaan we zeker nodig hebben voor de uitdagingen van de toekomst: vergrijzing, vereenzaming, ongelijkheid...</br></br>Persoonlijk contact vind ik superbelangrijk, ook in de gemeentelijke dienstverlening. Want niet iedereen is mee met de digitale trein. Vrij toegankelijke openingsuren in het gemeentehuis zouden zeker een stap zijn in de goede richting. ",
      foto: "Bea Landuyt.JPG",
    },
  ];

  const [currentPerson, setCurrentPerson] = useState(0);

  function setPerson(index: number, direct = true) {
    /*    if (!direct && window.innerWidth > 768) {
      index++;
    } */
    /*     console.log(index);
     */ if (index < 0) {
      setCurrentPerson(members.length - 1);
    } else if (index > members.length - 1) {
      setCurrentPerson(0);
    } else {
      setCurrentPerson(index);
    }
  }

  return (
    <article className="background-figures flex w-full border-t-8 border-orangelight">
      <Helmet>
        <title>Wie is wie | Koester 2290</title>
      </Helmet>
      <section className="container mx-auto flex flex-col justify-evenly gap-10 px-4 py-8 md:flex-row md:justify-between lg:px-16">
        <div className="flex w-full basis-[90%] items-center md:pl-10">
          <div className="flex grow flex-col items-center gap-6 lg:flex-row">
            <div className=" mx-auto  min-w-40 max-w-40 ">
              <img
                /*                 src={members[currentPerson].foto}
                 */ src={"/assets/kandidaten/" + members[currentPerson].foto}
                alt=""
                className=" aspect-square h-40 rounded-full object-cover "
              />
            </div>
            <div className="mr-auto mt-4  flex w-full flex-col gap-4">
              <header className="flex flex-col">
                <h2 className="font-koester text-4xl font-bold text-green">
                  {members[currentPerson].naam}
                </h2>
                <h3 className="-mt-2 w-full max-w-[60ch] text-xl  text-green">
                  {members[currentPerson].functie ?? "Nog niet ingevuld"}
                </h3>
              </header>

              <p
                className=" max-h-full w-full max-w-[60ch] font-bold leading-5 md:leading-6"
                dangerouslySetInnerHTML={{
                  __html: members[currentPerson].context,
                }}
              ></p>
            </div>
          </div>
        </div>
        <div className="flex min-w-[21rem] basis-[10%] items-center md:w-[21rem]">
          <MemberComponent
            members={members}
            currentPerson={currentPerson}
            setPerson={setPerson}
          />
        </div>
      </section>
    </article>
  );
}

export interface Person {
  naam: string;
  functie?: string;
  context: string;
  foto: string;
}

export function MemberComponent(props: {
  members: Person[];
  currentPerson: number;
  setPerson: any;
}) {
  const [api, setApi] = useState<CarouselApi>();
  const [carouselOrientation, setCarouselOrientation] = useState(
    window.innerWidth > 768 ? "vertical" : "horizontal",
  );

  useEffect(() => {
    const handleResize = () => {
      setCarouselOrientation(
        window.innerWidth > 768 ? "vertical" : "horizontal",
      );
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    if (api) {
      api.on("select", () => {
        props.setPerson(api.selectedScrollSnap(), false);
      });
    }

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [api, props]);

  function setPersonIntercept(index: number, direct: boolean) {
    api?.scrollTo(index);
    props.setPerson(index, direct);
  }

  return (
    <div className="relative flex w-full flex-col justify-center ">
      <Carousel
        setApi={setApi}
        opts={{
          align: "center", // start the carousel with selected item centered instead of at the start
          loop: true,
        }}
        orientation={
          carouselOrientation as "vertical" | "horizontal" | undefined
        }
        className="flex justify-center "
      >
        <div className="flex w-full max-w-[70vw] justify-center overflow-hidden rounded-[40px]  md:block ">
          <CarouselContent className="md:h-[18rem]">
            {props.members.map((member, index) => (
              <CarouselItem key={"member " + index} className="md:basis-1/3">
                <PersonButton
                  member={member}
                  currentPerson={props.currentPerson}
                  index={index}
                  setPerson={setPersonIntercept}
                />
              </CarouselItem>
            ))}
          </CarouselContent>
        </div>

        <CarouselPrevious className="z-20 translate-x-14 border-none bg-transparent text-7xl text-green hover:bg-transparent md:-translate-x-1/2 " />
        <CarouselNext className="z-20 -translate-x-14 border-none bg-transparent text-7xl text-green hover:bg-transparent md:-translate-x-1/2 " />
      </Carousel>

      {/*      <button onClick={props.nextPerson} id="previous-member" className="z-10">
        <FontAwesomeIcon className="text-7xl text-green" icon={faChevronDown} />
      </button> */}
    </div>
  );
}
