import React from "react";
import "./App.css";
import Home from "./pages/home";
import Navigation from "./components/navigation";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Footer from "./components/footer";
import UnderConstruction from "./pages/underConstruction";
import Programma from "./pages/programma";
import WieIsWie from "./pages/wieIsWie";
import Publicaties from "./pages/publicaties";
import OnderMoedersParaplu from "./posts/onderMoedersParaplu";
import Posts from "./pages/posts";
import { Helmet } from "react-helmet";
import Contact from "./pages/contact";
import MarkdownPage from "./components/markdownPage";

function App() {
  return (
    <main className="flex min-h-[100svh] flex-col">
      <Helmet>
        <title>Koester 2290</title>
        <meta
          name="description"
          content="Koester 2290, met een hart voor Vorselaar"
          data-react-helmet="true"
        />
        <meta
          property="og:title"
          content="Koester 2290"
          data-react-helmet="true"
        />
        <meta
          property="og:description"
          content="Koester 2290, met een hart voor Vorselaar"
          data-react-helmet="true"
        />
        <meta
          property="og:image"
          content="https://www.koester2290.be/assets/koester2290.png"
          data-react-helmet="true"
        />
        <meta property="og:type" content="article" data-react-helmet="true" />
        <meta
          property="og:url"
          content="https://www.koester2290.be/assets/koester2290.png"
          data-react-helmet="true"
        />
        <meta
          name="twitter:title"
          content="Koester 2290"
          data-react-helmet="true"
        />
        <meta
          name="twitter:description"
          content="Koester 2290, met een hart voor Vorselaar"
          data-react-helmet="true"
        />
        <meta
          name="twitter:image"
          content="https://www.koester2290.be/assets/koester2290.png"
          data-react-helmet="true"
        />
      </Helmet>
      <BrowserRouter>
        <NavigationWrapper />
        <section className="z-10 flex grow">
          <Routes>
            <Route path={"/"} element={<Home />} />
            <Route path={"/wie-is-wie"} element={<WieIsWie />} />
            <Route path={"programma"} element={<Programma />} />
            <Route path={"/programma/:slug"} element={<MarkdownPage />} />
            <Route path={"/publicaties"} element={<Publicaties />} />
            <Route path={"/contact"} element={<Contact />} />
            <Route path={"posts"} element={<Posts />}>
              <Route
                path="onder-moeders-paraplu"
                element={<OnderMoedersParaplu />}
              />
            </Route>
            <Route
              path={"*"}
              element={<UnderConstruction construction={false} />}
            />
          </Routes>
        </section>
        <Footer />
      </BrowserRouter>
    </main>
  );
}

function NavigationWrapper() {
  const location = useLocation();

  // Function to determine which navigation to show based on the current path
  const renderNavigation = () => {
    if (location.pathname.startsWith("/posts")) {
      // Use a different navigation for posts
      // return <NavigationForPosts />;

      return <Navigation isOnPostsPage={true} />;
    } else {
      return <Navigation />; // Default navigation
    }
  };

  return renderNavigation();
}

export default App;
