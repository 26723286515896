import { Helmet } from "react-helmet";

export default function OnderMoedersParaplu() {
  return (
    <article className="flex w-full items-center border-t-8 border-orangelight">
      <Helmet>
        <title>Onder Moeders Paraplu | Koester 2290</title>
        <meta
          name="description"
          content="Heeft u ook al onder de paraplu van moeder op het kerkhof gestaan
          terwijl er daar afscheid van haar werd genomen?"
        />
        <meta
          property="og:title"
          content="Onder Moeders Paraplu | Koester 2290"
        />
        <meta
          property="og:description"
          content="Heeft u ook al onder de paraplu van moeder op het kerkhof gestaan
          terwijl er daar afscheid van haar werd genomen?"
        />
        <meta
          property="og:image"
          content="https://www.koester2290.be/assets/posts/OnderMoedersParaplu.png"
        />
        <meta property="og:type" content="article" />
        <meta
          property="og:url"
          content="https://www.koester2290.be/assets/posts/onder-moeders-paraplu"
        />
        <meta
          name="twitter:title"
          content="Onder Moeders Paraplu | Koester 2290"
        />
        <meta
          name="twitter:description"
          content="Heeft u ook al onder de paraplu van moeder op het kerkhof gestaan
          terwijl er daar afscheid van haar werd genomen?"
        />
        <meta
          name="twitter:image"
          content="https://www.koester2290.be/assets/posts/OnderMoedersParaplu.png"
        />
      </Helmet>
      <section className="container mx-auto  flex max-w-[100ch] flex-col px-4 py-8 text-green sm:block [&>*]:mt-5">
        <h1 className="text-center font-koester text-5xl font-bold text-orangelight sm:text-7xl">
          Onder moeders paraplu...
        </h1>
        <img
          src="/assets/posts/OnderMoedersParaplu.png"
          className="order-1 mx-auto h-96 w-full max-w-[30rem] object-cover object-bottom sm:float-right sm:ml-10 sm:h-auto sm:w-56"
          alt="Een foto van een persoon met een paraplu"
        />
        <p className="order-0   text-xl">
          Heeft u ook al onder de paraplu van moeder op het kerkhof gestaan
          terwijl er daar afscheid van haar werd genomen?
        </p>
        <p className="order-0    text-2xl font-bold ">
          Onze raadsleden ijveren al meer dan 10 jaar voor een passende
          overdekte plaats op het kerkhof om droog en comfortabel afscheid te
          nemen van een dierbare.
        </p>

        <p className=" order-2 text-xl">
          We kregen geen gehoor. Al die tijd botsten we met onze vraag op een
          muur van stilzwijgen en kregen andere, grote projecten voorrang. We
          bleven op onze honger zitten, in de kou staan én ook – bij regen – in
          de drup!
        </p>
        <p className="order-2  !mt-1 text-xl font-bold">
          Enkele maanden geleden ging het bestuur uiteindelijk - na ons lang en
          volhoudend aandringen – er iets aan doen. Op onze vraag beloofde men
          ook overleg. Zo klonk het op de gemeenteraad althans. Het was te mooi
          om waar te zijn: een ‘samenproject’! Alle ideeën zouden welkom zijn.
        </p>
        <p className="order-2  text-xl">
          Helaas bleek dit inderdaad te mooi om waar te zijn.
        </p>
        <p className="order-2  text-xl">
          Recent mochten we via de pers vernemen dat er werk gemaakt zou worden
          van een “authentiek” afdak/houtconstructie. Het lijkt erop dat dit nog
          vlug als verkiezingsproject, zonder enig overleg gerealiseerd moet
          worden. Rustiek? Wat het juist wordt, moeten we afwachten.
          Beschuttend? Comfortabel? Met zitplaatsen? En misschien ook sanitair?
          Uit de wind?
        </p>
        <p className="order-2  text-xl">
          Jammer dat zelfs over de laatste rustplaats geen echt overleg kan
          bestaan, dat gestolen ideeën worden gebruikt om in dit verkiezingsjaar
          nog vlug te scoren. Een pluim op de hoed. Een gestolen of geleende
          pluim?
        </p>
        <p className="order-2  text-xl">
          Goed bestuur is niet enkel scoren.{" "}
          <span className="font-bold">
            {" "}
            Goed bestuur is ook overleg, zelfs met respect voor de minderheid.
          </span>{" "}
          Zeker voor het kerkhof, waar we toch allemaal gelijk zijn.
        </p>
      </section>
    </article>
  );
}
