import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import "../content/contentStyles.css";

export default function MarkdownPage() {
  const { slug } = useParams();
  const [content, setContent] = useState("");

  useEffect(() => {
    // Import the markdown file based on the route parameter
    import(`../content/${slug}.md`)
      .then((res) => {
        fetch(res.default)
          .then((response) => response.text())
          .then((text) => setContent(text));
      })
      .catch((err) => console.error(err));
  }, [slug]);

  return (
    <article className="background-figures flex w-full border-t-8 border-orangelight">
      <Helmet>
        <title>{slug?.replace("-", " ")} | Koester 2290</title>
      </Helmet>
      <section className="container mx-auto flex max-w-[85ch] items-center justify-between gap-10 px-4 py-8">
        <div className="flex w-full flex-col gap-6 text-xl text-green [&>ul>li]:py-1 [&>ul]:list-disc [&>ul]:pl-5">
          <ReactMarkdown>{content}</ReactMarkdown>
        </div>
      </section>
    </article>
  );
}
