import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export default function Programma() {
  return (
    <article className="background-figures flex w-full border-t-8 border-orangelight ">
      <Helmet>
        <title>Programma | Koester 2290</title>
      </Helmet>
      <section className="container mx-auto flex items-center justify-between gap-10 px-4 py-8">
        <div className=" flex  w-full flex-col gap-6 ">
          <h2 className="flex flex-col font-koester text-5xl font-bold text-green">
            <span className="hyphens-auto break-words">
              Ons verkiezingsprogramma voor u!
            </span>
          </h2>
          <div className="relative flex flex-col md:flex-row md:gap-10 ">
            <div id="home-left" className="flex w-full flex-col gap-6 ">
              {/*     <h2 className=" flex flex-col text-5xl font-bold text-green">
                <span>#2290 #Lijst3</span>
              </h2> */}
              <h2 className=" flex flex-col gap-4 text-xl font-bold text-green">
                <span>
                  Al vele jaren volgen onze raadsleden de dossiers in onze
                  gemeente van nabij op. In aanloop naar deze verkiezingen
                  hebben we ingezet op verruiming en vernieuwing. We spraken met
                  veel inwoners en hebben gevoeld wat er leeft in Vorselaar. Zo
                  zijn we gekomen tot een breed en ambitieus programma. Met dit
                  project gaan we voor een mooie toekomst voor ons dorp.
                </span>{" "}
                <span>
                  Goed zorg dragen voor de vele troeven van Vorselaar (ze
                  koesteren) en sterk inzetten op de kerntaken van de gemeente
                  vormen de rode draad.
                </span>{" "}
                <span>We bundelen onze voorstellen in 5 thema’s.</span>
              </h2>
              <div className="flex h-full  w-full flex-col justify-center gap-6 [&>*:hover]:opacity-90">
                {/*    <button className="rounded-lg bg-orangedark p-2 text-3xl font-bold text-white">
                  Vorselaar voor <span className="italic">iedereen</span>
                </button>
                <button className="rounded-lg bg-green p-2 text-3xl font-bold text-white">
                  Onze waarden
                </button>
                <button className="rounded-lg bg-orangelight p-2 text-3xl font-bold text-white">
                  Voor een <span className="italic">sterke</span> toekomst!
                </button> */}
                <Link
                  to={"/programma/2290, veilig en mooi"}
                  className="rounded-lg bg-orangedark p-2 text-center text-3xl font-bold text-white"
                >
                  2290, veilig en mooi
                </Link>
                <Link
                  to={"/programma/Een bruisend 2290"}
                  className="rounded-lg bg-green p-2 text-center  text-3xl  font-bold text-white"
                >
                  Een bruisend 2290
                </Link>
                <Link
                  to={"/programma/2290 voor iedereen"}
                  className="rounded-lg bg-orangelight p-2 text-center  text-3xl  font-bold text-white"
                >
                  2290 voor iedereen
                </Link>
                <Link
                  to={"/programma/Een duurzaam 2290"}
                  className="rounded-lg bg-yellow p-2 text-center  text-3xl  font-bold text-white"
                >
                  Een duurzaam 2290
                </Link>
                <Link
                  to={"/programma/2290, klaar voor de toekomst"}
                  className="rounded-lg bg-orangedark p-2 text-center  text-3xl  font-bold text-white"
                >
                  2290, klaar voor de toekomst
                </Link>
              </div>
            </div>
            <div
              id="home-right"
              className=" sticky top-0 flex h-full w-full  items-center justify-center"
            >
              <img
                draggable="false"
                /*             style={{ objectPosition: "center center" }}
                 */ className="programma-heart-twos-clip  aspect-square  scale-[.785] object-cover object-[50%_110%]"
                src="assets/Foto Gemeentehuis.JPG"
                alt="Groepsfoto Koester 2290"
              />
              {/*  <div className="absolute bottom-0 left-0 z-10 h-[7rem] w-[16rem] bg-white"></div>
              <div className="absolute bottom-0 right-0 z-10 h-[9rem] w-[16rem] bg-white"></div> */}
              <img
                draggable="false"
                src="/assets/test2.svg"
                alt="Koester logo"
                className=" absolute  z-20 w-full translate-x-3 -rotate-[7deg] "
              />
            </div>
          </div>
        </div>
      </section>
    </article>
  );
}
