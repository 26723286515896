export default function Home() {
  return (
    <article className="background-figures flex w-full items-center bg-yellow">
      <section className="container mx-auto flex flex-col justify-evenly gap-6 px-4 py-8 sm:flex-row">
        <div
          id="home-left"
          className="flex  grow basis-2 flex-col justify-center gap-6"
        >
          <h1 className="font-koester text-5xl font-bold text-green">
            Welkom bij koester 2290!
          </h1>
          <p className=" text-xl font-bold text-white">
            Een sterke ploeg staat klaar, met ervaring én met nieuw talent, met
            jong en oud, en bovenal met een hart voor ons dorp. We stellen onze
            kandidaten hier aan u voor. Je vindt hier ook ons brede en
            ambitieuze programma waarmee we een nieuw elan willen geven aan het
            bestuur in Vorselaar. Wij staan klaar, we hopen dat we uw vertrouwen
            waard zijn op 13 oktober. U mag op ons rekenen!
          </p>
        </div>
        <div
          id="home-right "
          className=" relative  flex grow basis-3 items-center justify-center"
        >
          <img
            draggable="false"
            /*             style={{ objectPosition: "center center" }}
             */ className="heart-twos-clip aspect-[3/2]  object-cover object-[-12px_20px]"
            src="assets/Groepsfoto Koester 2290.JPG"
            alt="Groepsfoto Koester 2290"
          />
        </div>
      </section>
    </article>
  );
}
