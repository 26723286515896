import { Publicatie } from "../pages/publicaties";
export default function PublicatieCard(props: { publicatie: Publicatie }) {
  return (
    <div className="publicatie-card w-80 rounded-xl bg-green p-2  ">
      <div className="gap- flex h-full flex-col justify-between rounded-lg bg-white ">
        <img
          draggable="false"
          src={props.publicatie.image ?? "https://picsum.photos/500"}
          alt={`Foto van publicatie ${props.publicatie.title}`}
          className=" m-6 mb-0 rounded-lg object-cover object-top" /* aspect-[3/2] */
        />
        <div className="flex h-full flex-col justify-between gap-6 p-6">
          <h3 className="mx-2 text-center font-koester text-3xl font-bold text-orangedark">
            {props.publicatie.title}
          </h3>
          <a
            draggable="false"
            href={`/assets/publicaties/${props.publicatie.fileName}`}
            target="_blank"
            rel="noreferrer"
            className={`mx-2 flex select-none items-center justify-center gap-2 rounded-lg bg-orangelight px-4 py-2 text-lg font-semibold text-white hover:opacity-80`}
          >
            Bekijk pdf
          </a>{" "}
        </div>
      </div>
    </div>
  );
}
