import { Outlet, useLocation } from "react-router-dom";
import UnderConstruction from "./underConstruction";

export default function Posts() {
  const location = useLocation();
  return (
    <>
      {location.pathname.startsWith("/posts/") &&
      location.pathname.length > "/posts/".length ? (
        <Outlet />
      ) : (
        <UnderConstruction construction={false} />
      )}
    </>
  );
}
